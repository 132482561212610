import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rwluarnegeri: {
      id: '',
      negara: '',
      waktu: {
        mulai: '',
        selesai: ''
      },
      tujuan: '',
      sumberBiaya: '',
      suratKeputusan: {
        pejabat: '',
        nomor: '',
        tanggal: ''
      }
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwluarnegeri = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})
