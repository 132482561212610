import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwluarnegeri: {
      id: '',
      negara: '',
      waktu: {
        mulai: '',
        selesai: ''
      },
      tujuan: '',
      sumberBiaya: '',
      suratKeputusan: {
        pejabat: '',
        nomor: '',
        tanggal: ''
      }
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwluarnegeri = value
    },
  }
})
