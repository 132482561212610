<template>
  <div>
    <v-card outlined flat>
      <v-app-bar color="primary" short dense dark flat>
        <v-toolbar-title>Pengalaman Luar Negeri</v-toolbar-title>
        <v-spacer></v-spacer>

        <div class="mr-3 mt-1">
          <rwluarnegeriModalAdd />
        </div>

        <div style="max-width: 300px;">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <!-- <v-tab href="#tab-2" @click="getSAPK()">
              <h5>SAPK</h5>
            </v-tab> -->
          </v-tabs>
        </div>
      </v-app-bar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" class="pa-5">
          <rwluarnegeriSIMASN />
        </v-tab-item>
        <!-- <v-tab-item value="tab-2" class="pa-5">
          <rwluarnegeriSAPK />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import getData from '@/store/asn/rwluarnegeri/tabs'
import rwluarnegeriSIMASN from '@/components/asn/profil/rwluarnegeri/rwluarnegeri'
// import rwluarnegeriSAPK from '@/components/asn/profil/rwluarnegeri/SAPK_rwluarnegeri_Modal'
import rwluarnegeriModalAdd from '@/components/asn/profil/rwluarnegeri/ModalAdd'

export default {
  name: 'DataUtama',

  components: {
    rwluarnegeriSIMASN,
    // rwluarnegeriSAPK,
    rwluarnegeriModalAdd
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK
      },

      set(value) {
        getData.commit('getSAPK', value)
      }
    }
  },

  data: () => ({
    tab: null
  }),

  methods: {
    getSAPK() {
      getData.commit('getSAPK', true)
      getData.commit('getSIMASN', false)
    },
    getSIMASN() {
      getData.commit('getSIMASN', true)
      getData.commit('getSAPK', false)
    }
  }
}
</script>
